import React, { useEffect } from "react";

function GDPR() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="legal-section container">
      <div className="legal-wrap">
        <h2>
          General Data Protection Regulation <span>Compliance</span>
        </h2>
        <p>
          At Fish2shark, accessible from{" "}
          <a
            href="https://www.fish2shark.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.fish2shark.com
          </a>
          , one of our main priorities is the privacy of our visitors. This
          Privacy Policy document contains types of information that is
          collected and recorded by Fish2shark and how we use it.
        </p>
        <p>
          This policy sets out how Fish2shark will process data and the rights
          of data subjects under data protection law, including in the EU the
          General Data Protection Regulation (GDPR).
        </p>
        <p>
          The following procedures and principles shall be followed at all times
          by company employees, agents, clients/customers, contractors, or other
          parties working in association with Fish2shark Private Limited. If you
          have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us at{" "}
          <span className="highlight">support@fish2shark.com</span>.
        </p>
        <h5>General Data Protection Regulation (GDPR)</h5>
        <p>We are the Data Controller of your information.</p>
        <span>
          Fish2shark legal basis for collecting and using the personal
          information described in this Privacy Policy depends on the Personal
          Information we collect and the specific context in which we collect
          the information:
        </span>
        <ul>
          <li>Fish2shark needs to perform a contract with you</li>
          <li>You have given Fish2shark permission to do so</li>
          <li>
            Processing your personal information is in Fish2shark legitimate
            interests
          </li>
          <li>Fish2shark needs to comply with the law</li>
        </ul>
        <p>
          Fish2shark will retain your personal information only for as long as
          is necessary for the purposes set out in this Privacy Policy. We will
          retain and use your information to the extent necessary to comply with
          our legal obligations, resolve disputes, and enforce our policies.
        </p>
        <p>
          If you are a resident of the European Economic Area (EEA), you have
          certain data protection rights. If you wish to be informed what
          Personal Information we hold about you and if you want it to be
          removed from our systems, please contact us.
        </p>
        <h5>Log Files</h5>
        <p>
          Fish2shark follows a standard procedure of using log files. These
          files log visitors when they visit websites. All hosting companies do
          this and a part of hosting services' analytics. The information
          collected by log files include internet protocol (IP) addresses,
          browser type, Internet Service Provider (ISP), date and time stamp,
          referring/exit pages, and possibly the number of clicks. These are not
          linked to any information that is personally identifiable. The purpose
          of the information is for analyzing trends, administering the site,
          tracking users' movement on the website, and gathering demographic
          information.
        </p>
        <h5>Privacy Policies</h5>
        <p>
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of Fish2shark.
        </p>
        <p>
          Third-party ad servers or ad networks use technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on Fish2shark, which are sent
          directly to users' browsers. They automatically receive your IP
          address when this occurs. These technologies are used to measure the
          effectiveness of their advertising campaigns and/or to personalize the
          advertising content that you see on websites that you visit.
        </p>
        <p>
          Note that Fish2shark has no access to or control over these cookies
          that are used by third-party advertisers.
        </p>
        <h5>Third Party Privacy Policies</h5>
        <p>
          Fish2shark's Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt-out of certain options.
        </p>
        <p>
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers'
          respective websites.
        </p>
        <h5>Children's Information</h5>
        <p>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>
        <p>
          Fish2shark does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </p>
        <h5>Consent</h5>
        <p>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </p>
        <hr />
        <p>
          You can contact us via email at{" "}
          <span className="highlight">support@fish2shark.com</span> for any futher
          queries or clarifications.
        </p>
      </div>
    </section>
  );
}

export default GDPR;
