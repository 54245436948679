import React from "react";

// Image Imports

import pageNotFound from "../../../images/pageNotFound.jpg";

// End of Image Imports

function PageNotFound() {
  return (
    <section className="error-404-wrapper common-wrapper">
      <div className="container">
        <div className="error-404-content">
          <h3>Page not found</h3>
          <h5>Please check your website URL and try again</h5>
          <img src={pageNotFound} alt="404" />
        </div>
      </div>
    </section>
  );
}

export default PageNotFound;
