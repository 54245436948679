import React, { useState, useEffect } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Breadcrumbs from "../Common/Breadcrumbs";
import "./Legal.css";

import Terms from "./Terms";
import Privacy from "./Privacy";
import GDPR from "./GDPR";

function Legal() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Terms & Conditions | Fish2shark Inc., Ontario, CA";
  }, []);
  //   Tabs
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => setActiveTab(tab);
  return (
    <>
      <Breadcrumbs name="Terms and Conditions" />
      <section className="legal-section-wrapper">
        <div className="container custom">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={`${activeTab === "1" ? "active" : ""}`}
                onClick={() => {
                  toggle("1");
                  document.title =
                    "Terms & Conditions | Fish2shark Inc., Ontario, CA";
                }}
              >
                Terms &amp; Conditions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === "2" ? "active" : ""}`}
                onClick={() => {
                  toggle("2");
                  document.title =
                    "Privacy Policy | Fish2shark Inc., Ontario, CA";
                }}
              >
                Privacy Policy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === "3" ? "active" : ""}`}
                onClick={() => {
                  toggle("3");
                  document.title =
                    "General Data Protection Regulation Policy | Fish2shark Inc., Ontario, CA";
                }}
              >
                General Data Protection Regulation Compliance
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Terms toggle={toggle} />
            </TabPane>
            <TabPane tabId="2">
              <Privacy />
            </TabPane>
            <TabPane tabId="3">
              <GDPR />
            </TabPane>
          </TabContent>
        </div>
      </section>
    </>
  );
}

export default Legal;
