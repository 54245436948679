import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import "./Careers.css";
import Breadcrumbs from "../Common/Breadcrumbs";
import Consulting from "../Common/Consulting";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// Image Imports

import careersImg_1 from "../../../images/careerImage_1.jpg";
import careersImg_2 from "../../../images/careerImage_2.jpg";
import careersImg_3 from "../../../images/careerImage_3.jpg";
import careersImg_4 from "../../../images/careerImage_4.jpg";
import careersImg_5 from "../../../images/careerImage_5.jpg";
import careersImg_6 from "../../../images/careerImage_6.jpg";

// End of Image Imports

function Careers() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Careers - Fish2shark - Canada's Favourite Marketing Agency | The Ultimate Business Solution for your Dream Business | Fish2shark Inc., Ontario, CA`;
  }, []);

  const careersList = [
    {
      title: "Graphic Designer Intern",
      location: "Work from Home",
      skills: "Premier Pro, Canva",
    },
    {
      title: "Admin Intern",
      location: "Work from Home",
      skills: "Management",
    },
    {
      title: "Sales Manager",
      location: "Work from Home",
      skills: "Sales & Marketing Management",
    },
  ];

  const rolesList = [
    "We are looking for candidates who are energetic, keen in learning, hard-working and who are ready to prove their core competencies.",
    "The candidate should have the ability to build a conversation with clients and build a prospect for conversion of sales.",
    "The work timing will be based on the Canadian EST and the candidate should be able to adapt to it.",
    "The tasks assigned from time to time are expected to be completed at the stipulated time.",
    "This internship attracts a high pay for top performing candidates and certificates will be provided as well for the internship period.",
    "Anyone can apply for this internship regardless of the nationality, race or gender.",
  ];

  const options = {
    loop: true,
    margin: 10,
    items: 1,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 4000,
    autoplayHoverPause: true,
    responsiveClass: true,
    animateOut: "fadeOut",
    smartSpeed: 450,
  };

  const careerImages = [
    { title: "Careers 1", imgURL: careersImg_1 },
    { title: "Careers 2", imgURL: careersImg_2 },
    { title: "Careers 3", imgURL: careersImg_3 },
    { title: "Careers 4", imgURL: careersImg_4 },
    { title: "Careers 5", imgURL: careersImg_5 },
    { title: "Careers 6", imgURL: careersImg_6 },
  ];

  return (
    <section className="careers-wrapper common-wrapper">
      <Breadcrumbs name="Careers" />
      <div className="container">
        <div className="careers-header">
          <Row>
            <Col sm="12" lg="6" md="6">
              <h6>The perfect start to your career</h6>
              <h4>
                Get <span>Paid Internships</span> to kickstart your{" "}
                <span>career</span>
              </h4>
              <h5>
                North America's Favourite Marketing and Advertising Agency
              </h5>
              <p>
                We offer paid interships to aspiring individuals who are just
                kickstarting their career where they can work from the comfort
                of their homes. Start your career with us for exponential growth
                and make a difference in the world of marketing.
              </p>
              <a
                href="mailto:support@fish2shark.com"
                rel="noreferrer noopener"
                target="_blank"
              >
                <div className="resumes-email">
                  <i class="bx bx-mail-send"></i>
                  <h5>
                    Send your resumes at <span>support@fish2shark.com</span> to
                    apply for the internship
                  </h5>
                </div>
              </a>
            </Col>
            <Col sm="12" lg="6" md="6">
              <div className="intern-banner">
                <OwlCarousel className="owl-theme" {...options}>
                  {careerImages.map((item) => {
                    return (
                      <div className="item">
                        <img
                          src={item.imgURL}
                          data-mdb-lazy-load-init
                          data-mdb-lazy-src
                          alt="Careers"
                        />
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>
            </Col>
          </Row>
        </div>
        <h3>Available Job Roles</h3>
        <div className="careers-item-wrap">
          {careersList.map((item) => {
            return (
              <div className="carr-item">
                <h4>{item.title}</h4>
                <h6>{item.location}</h6>
                <h5>
                  <i class="bx bx-laptop"></i>
                  Skills Required
                </h5>
                <p>{item.skills}</p>
              </div>
            );
          })}
        </div>
        <h3>Job Description</h3>
        <div className="role-item-wrap">
          <ul className="role-item">
            {rolesList.map((item) => {
              return (
                <li>
                  <i class="bx bx-check"></i>
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
        <Consulting
          title="Get Free Consulting"
          message="I am interested to join your company as an Intern!"
        />
      </div>
    </section>
  );
}

export default Careers;
