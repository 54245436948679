import React, { useState, useEffect } from "react";

import Logo from "../../../images/logo.png";

function Preloader() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <div className={`preloader ${loading ? "" : "pre-hide"}`}>
      <div className="preloader-content">
        <h5>Awesomeness Loading</h5>
        <img src={Logo} alt="Logo" width={200} />
        <h6>
          fish2<span>shark</span>
        </h6>
        <div className="pre-progress">
          <div className="prog-line"></div>
        </div>
      </div>
    </div>
  );
}

export default Preloader;
