import React, { useState } from "react";
import { Link } from "react-router-dom";

// Image Imports

import consulting from "../../../images/consulting.gif";
import RequestCallback from "./RequestCallback";

// End of Image Imports

function Consulting({ message }) {
  // Modal

  const [modal, setModal] = useState(false);

  //  Toggle Modal

  const toggle = () => setModal(!modal);
  return (
    <>
      <div className="consulting-wrap">
        <div className="cw-left">
          <img
            src={consulting}
            alt="Consulting"
            data-mdb-lazy-load-init
            data-mdb-lazy-src
          />
          <h4>
            Get <span>Business Consulting</span> worth CA$ 49 for{" "}
            <span>absolutely free!</span>
          </h4>
          <h5>
            We offer you free consulting for a limited period of time to start
            your dream business. Grab the opportunity now!
          </h5>
        </div>
        <Link onClick={toggle}>
          <button className="consult-btn">
            <i class="bx bx-conversation"></i>
            Get Free Consulting
          </button>
        </Link>
      </div>
      <RequestCallback
        isOpen={modal}
        toggle={toggle}
        title="Get Free Consulting"
        message={message}
      />
    </>
  );
}

export default Consulting;
