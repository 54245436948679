import { Toaster } from "react-hot-toast";

export default function AppToaster() {
  return (
    <Toaster
      isOpen={true}
      position="top-center"
      reverseOrder={false}
      containerClassName="custom-toast"
      containerStyle={{}}
      toastOptions={{
        // Define default options
        style: {
          fontFamily: "var(--bold)",
        },
        // Default options for specific types
        loading: {
          theme: {
            primary: "green",
            secondary: "black",
          },
          style: {
            background: "#fff",
            color: "#212121",
            width: "100%",
          },
        },
        success: {
          duration: 7000,
          theme: {
            primary: "green",
            secondary: "black",
          },
          style: {
            background: "#fff",
            color: "teal",
            width: "100%",
            fontSize: "18px",
          },
        },
        error: {
          duration: 4000,
          theme: {
            primary: "green",
            secondary: "black",
          },
          style: {
            background: "#fff",
            color: "#f10b0b",
            width: "100%",
            fontSize: "18px",
          },
        },
      }}
    />
  );
}
