import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import "./Contact.css";
import Breadcrumbs from "../Common/Breadcrumbs";
import Consulting from "../Common/Consulting";

// Image Imports

import contactImg from "../../../images/contactImg.jpg";
import whatsappIcon from "../../../images/whatsapp.svg";

// End of Image Imports

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Contact Us - Fish2shark - Canada's Favourite Marketing Agency | The Ultimate Business Solution for your Dream Business | Fish2shark Inc., Ontario, CA`;
  }, []);

  return (
    <section className="contact-wrapper common-wrapper">
      <Breadcrumbs name="Contact us" />
      <div className="container">
        <Row>
          <Col sm="12" lg="7" md="7">
            <div className="contact-header common-header">
              <h6>Success is just a click away</h6>
              <h2>
                Connect with <span>Us</span>
              </h2>
            </div>
            <div className="contact-info-item-wrap">
              <Row>
                <Col sm="12" lg="6" md="6">
                  <a
                    href="tel:+15488883355"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <div className="contact-info-item">
                      <i class="bx bxs-phone"></i>
                      <h5>Call us</h5>
                      <h4>+1 (548) 888-3355</h4>
                    </div>
                  </a>
                </Col>
                <Col sm="12" lg="6" md="6">
                  <a
                    href="mailto:support@fish2shark.com"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <div className="contact-info-item">
                      <i class="bx bxs-envelope"></i>
                      <h5>Email us</h5>
                      <h4>support@fish2shark.com</h4>
                    </div>
                  </a>
                </Col>
                <Col sm="12" lg="6" md="6">
                  <a
                    href="https://wa.me/+15488883355"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div className="contact-info-item">
                      <img src={whatsappIcon} alt="WhatsApp" />
                      <h5>WhatsApp</h5>
                      <h4>+1 (548) 888-3355</h4>
                    </div>
                  </a>
                </Col>
                <Col sm="12" lg="6" md="6">
                  <a
                    href="https://maps.app.goo.gl/cbUV9XK2V1iqcpC4A"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <div className="contact-info-item">
                      <i class="bx bxs-map"></i>
                      <h5>Locate us</h5>
                      <h4>
                        99, Pond Mills Road, London, <br /> Ontario, Canada N5Z
                        0B4
                      </h4>
                    </div>
                  </a>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm="12" lg="5" md="5">
            <div className="contact-right-img">
              <img
                src={contactImg}
                alt="Contact"
                data-mdb-lazy-load-init
                data-mdb-lazy-src
              />
            </div>
          </Col>
        </Row>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2919.3913366940906!2d-81.2070557239968!3d42.970029471142716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882ef347d01531cf%3A0x5cb17a276e3a7b2e!2s99%20Pond%20Mills%20Rd%2C%20London%2C%20ON%20N5Z%203X3%2C%20Canada!5e0!3m2!1sen!2sin!4v1710269729541!5m2!1sen!2sin"
        width={"100%"}
        height={"350"}
        style={{ border: "0" }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        title="Fish2shark Location"
        className="map-wrap"
      ></iframe>
      <div className="container">
        <Consulting message="I want to know more about your company!" />
      </div>
    </section>
  );
}

export default Contact;
