import React, { useState } from "react";
import RequestCallback from "./RequestCallback";

function FloatingMenu() {
  // Modal
  const [modal, setModal] = useState(false);

  //  Toggle Modal
  const toggle = () => setModal(!modal);
  return (
    <>
      <section className="floating-menu">
        <ul>
          <a
            href="https://wa.me/+15488883355"
            target="_blank"
            rel="noreferrer noopener"
          >
            <li data-content="WhatApp">
              <i class="bx bxl-whatsapp"></i>
            </li>
          </a>
          <a href="tel:+15488883355" rel="noreferrer noopener">
            <li data-content="Call">
              <i class="bx bxs-phone"></i>
            </li>
          </a>
          <a
            href="https://www.instagram.com/fish2shark2024/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <li data-content="Instagram">
              <i class="bx bxl-instagram"></i>
            </li>
          </a>
          <a href="mailto:support@fish2shark.com" rel="noreferrer noopener">
            <li data-content="Email">
              <i class="bx bx-envelope"></i>
            </li>
          </a>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              toggle();
            }}
            rel="noreferrer noopener"
          >
            <li data-content="Request a Call">
              <i class="bx bxs-phone-incoming"></i>
            </li>
          </a>
        </ul>
      </section>
      <RequestCallback
        isOpen={modal}
        toggle={toggle}
        title="Request a call"
        message="I want to know more about your company!"
      />
    </>
  );
}

export default FloatingMenu;
