import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Breadcrumbs from "../Common/Breadcrumbs";
import Consulting from "../Common/Consulting";
import "./Services.css";

// Image Imports

import servicesImg from "../../../images/services.jpg";

// End of Image Imports

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Our Services - Fish2shark - Canada's Favourite Marketing Agency | The Ultimate Business Solution for your Dream Business | Fish2shark Inc., Ontario, CA`;
  }, []);

  const servicesList = [
    {
      title: "Adveristing via Flyers",
      subtitle:
        "A flyer is the most ancient way of marketing your business but an effective one since your prospect gets a physical copy of your business information which can also potentially spread through word of mouth. We design inspiring flyers to showcase your business and we distribute them by running a  campaign for you to your target audience.",
      iconName: "book-content",
      categories: [
        {
          type: "300 Flyers",
          price: "CA$ 499",
        },
        {
          type: "500 Flyers",
          price: "CA$ 599",
        },
        {
          type: "1000 Flyers",
          price: "CA$ 799",
        },
        {
          type: "More than 1000 Flyers",
          price: false,
        },
      ],
    },
    {
      title: "Content Creation",
      subtitle: `A brilliant video can get the attention of a
      potential customer very quickly as psychology suggests. We offer a complete package of product Photography, Marketing Video Creation services involving scripting, direction, shooting and post production.`,
      iconName: "video-recording",
      categories: [
        {
          type: "Video Advertisement",
          price: "CA$ 999",
        },
        {
          type: "Product Photoshoot",
          price: "CA$ 250",
        },
        {
          type: "For Enquiry",
          price: false,
        },
      ],
    },
    {
      title: "Jingle/Song for your business",
      subtitle: `A catchy jingle of any brand tend to reverberate
      in our ears making an impact. We can compose a fitting Jingle 
      for your brand your audience will love bringing prominence to your business.`,
      iconName: "music",
      categories: [
        {
          type: "Composing Jingle",
          price: "CA$ 499",
        },
        {
          type: "Video Shoot for Jingle",
          price: "CA$ 999",
        },
        {
          type: "For Enquiry",
          price: false,
        },
      ],
    },
    {
      title: "Sales and Marketing Workshops / Training",
      subtitle:
        "Sales is the epicenter of any company's success. We can analyze your company's sales reports and marketing strategies to attain best results. We can train your sales professionals and also the entire marketing team to boost your sales ultimately raising your brand value in the market.",
      iconName: "bar-chart-square",
      categories: [
        {
          type: "Workshop / Training",
          price: "CA$ 499",
        },
        {
          type: "For Enquiry",
          price: false,
        },
      ],
    },
    {
      title: "Lead Generation using Data",
      subtitle:
        "Data of targetted audience are like gold. We possess enormous data of potential clients your business can strategically target. This will make sure your conversion rate becomes more prominent.",
      iconName: "network-chart",
      categories: [
        {
          type: "100 Potential Leads",
          price: "CA$ 499",
        },
        {
          type: "200 Potential Leads",
          price: "CA$ 849",
        },
        {
          type: "300 Potential Leads",
          price: "CA$ 1099",
        },
        {
          type: "More than 300 Leads",
          price: false,
        },
      ],
    },
    {
      title: "Fractional Hiring",
      subtitle:
        "Worried about building a sales team or not getting the right professional for your marketing needs? Now you can hire a professional as per your wish with a day's cost less than the average salary.",
      iconName: "briefcase",
      categories: [
        {
          type: "Sales Professional",
          price: "CA$ 199 / 5 hours",
        },
        {
          type: "For Enquiry",
          price: false,
        },
      ],
    },
    {
      title: "Podcast and Live Radio",
      subtitle:
        "People love to listen to the radio and what better way it is to let your brand heard loud and clear.We can record an ad podcast and publishing it live in local radio stations across North America.",
      iconName: "podcast",
      categories: [
        {
          type: "Recording Podcast",
          price: "CA$ 49 / per session",
        },
        {
          type: "Publising",
          price: "CA$ 199",
        },
        {
          type: "For Enquiry",
          price: false,
        },
      ],
    },
    {
      title: "Fulfillment Center Facility",
      subtitle:
        "Planning to sell your products in bulk here in Canada and worrying about a warehouse facility? We got you covered! We offer warehouse facility at pocket-friendly prices to store your products safe and sound.",
      iconName: "buildings",
      categories: [
        {
          type: "Inventory Storage",
          price: "CA$ 149 / per month",
        },
        {
          type: "For Enquiry",
          price: false,
        },
      ],
    },
    {
      title: "Website Design, Development & Deployment",
      subtitle:
        "A website is the index of your digital presence. Let customers know about your business by showcasing them in beautifully designed web pages tailor-made to fit your business needs.",
      iconName: "code-alt",
      categories: [
        {
          type: "Domain / Hosting",
          price: "CA$ 199 / 2 years",
        },
        {
          type: "Content / Design / Development",
          price: "CA$ 599",
        },
        {
          type: "Maintenance",
          price: "Free for first 3 months",
        },
        {
          type: "For Enquiry",
          price: false,
        },
      ],
    },
  ];

  return (
    <section className="services-wrapper common-wrapper">
      <Breadcrumbs name="Services" />
      <div className="container">
        <div className="serv-header common-header">
          <Row>
            <Col sm="12" lg="6" md="6">
              <h6>What we offer</h6>
              <h2>
                Our <span>Services</span>
              </h2>
              <p>
                We offer the best personalized marketing solutions in North
                America for your dream business at affordable costs to make your
                money's worth. We have an entire package of solutions which
                cater to your business needs making you not worry about any
                phase of your company's advertising journey. Our services are
                precisely formulated with practical strategies and curation to
                ace through the competition and stand out in the market. Choose
                from our variety of services and experience the difference!
              </p>
            </Col>
            <Col
              sm="12"
              lg="6"
              md="6"
              className="d-flex align-items-center justify-content-center"
            >
              <div className="serv-img">
                <img
                  src={servicesImg}
                  alt="Services"
                  data-mdb-lazy-load-init
                  data-mdb-lazy-src
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="services-list-wrap">
          <Row>
            <Col sm="12" lg="12" md="12">
              <div className="serv-list-item highlighted">
                <div className="serv-list-head">
                  <div className="serv-icon">
                    <i class="bx bx-laptop"></i>
                  </div>
                  <div>
                    <h3>Programmatic Advertisements</h3>
                    <h4>
                      We offer programmatic advertisements by displaying ad
                      banners in various targeted websites to register your
                      online presence to your potential audience which is a key
                      factor in the modern day of internet.
                    </h4>
                  </div>
                </div>
                <div className="serv-list-content">
                  <div className="sr-rt-item">
                    <h5>Programmatic Ads</h5>
                    <h4>CA$ 499</h4>
                  </div>
                  <div className="sr-rt-item">
                    <h5>For Enquiry</h5>
                    <h4>
                      <Link to="/contact">Contact Us</Link>
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
            {servicesList.map((item) => {
              return (
                <Col sm="12" lg="4" md="6">
                  <div className="serv-list-item">
                    <div className="serv-list-head">
                      <div className="serv-icon">
                        <i class={`bx bx-${item.iconName}`}></i>
                      </div>
                      <div>
                        <h3>{item.title}</h3>
                        <h4>{item.subtitle}</h4>
                      </div>
                    </div>
                    <div className="serv-list-content">
                      {item.categories.map((category) => {
                        return (
                          <div className="sr-rt-item">
                            <h5>{category.type}</h5>
                            <h4>
                              {category.price}
                              {category.price === false ? (
                                <Link to="/contact">Contact Us</Link>
                              ) : null}
                            </h4>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
        <Consulting message="I want to know more about your services!" />
      </div>
    </section>
  );
}

export default Services;
