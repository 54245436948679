import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import Breadcrumbs from "../Common/Breadcrumbs";
import Consulting from "../Common/Consulting";
import "./About.css";

// Image Imports

import aboutImg from "../../../images/about.jpg";
import vision from "../../../images/vision.jpg";
import mission from "../../../images/mission.jpg";
import values from "../../../images/values.jpg";

// End of Image Imports

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `About Us - Fish2shark - Canada's Favourite Marketing Agency | The Ultimate Business Solution for your Dream Business | Fish2shark Inc., Ontario, CA`;
  }, []);
  return (
    <section className="about-wrapper common-wrapper">
      <Breadcrumbs name="About us" />
      <div className="container">
        <div className="common-header abt-header">
          <Row>
            <Col sm="12" lg="6" md="6">
              <div className="abt-img-left">
                <img
                  src={aboutImg}
                  data-mdb-lazy-load-init
                  data-mdb-lazy-src
                  alt="About"
                />
              </div>
            </Col>
            <Col sm="12" lg="6" md="6">
              <div className="abt-content">
                <h6>Who we are</h6>
                <h2>
                  About the <span>Company</span>
                </h2>
                <div className="abt-body">
                  <p>
                    The inception of this company in Canada is the conjugation
                    of few like-minded people aiming to provide best-in-class
                    marketing solutions for companies, especially early-bird
                    startups which struggle in the initial phase. The prime aim
                    was to propel the growth of a startup multi-fold amid the
                    growing competition in North America. It requires enormous
                    planning, strategizing practical scenerios upfront and
                    executing them to perfection.
                  </p>
                </div>
              </div>
            </Col>
            <Col sm="12" lg="12" md="12">
              <p>
                We as a team are the best advertising agency in Canada having
                the expertise to sculpt a startup from day one and thus offering
                our range of marketing solutions. We came up with the name
                denoting our ability to transform a company which is initially a
                fish in the market to becoming a shark, outlasting all
                competition with smart planning and strategies. We offer
                customized solutions which differ from business to business and
                our market research helps us to find the best strategy/plan
                there is which suits your business need to the fullest.
              </p>
              <p>
                We have a data-driven approach to target the right audience in
                Ontario Province. Since this Province has proved to be the heart
                of Canada, our aim is to establish our marketing architecture
                aligning with the potential audience in this geographical
                region. We have had our fair share of customers who were
                extremely elated with our service where we offered a customized
                service to compliment their business needs.
              </p>
              <p>
                We have composed many Jingles, Video Shoots, Photo Shoots for
                various business ranging from food, fashion, medical industries
                and more. We also offer free consulting to kickstart your
                business to understand your requirement culminating in precise
                solutions for your dream business. We have a wide range of
                marketing solutions to choose from making us the ultimate choice
                for your marketing needs.
              </p>
            </Col>
          </Row>
        </div>
        <div className="mvv-items-wrap">
          <Row>
            <Col sm="12" lg="4" md="4">
              <div className="mvv-item">
                <div className="mvv-img">
                  <img src={vision} alt="Vision" />
                </div>
                <div className="mvv-body">
                  <h4>Our Vision</h4>
                  <p>
                    To create a customer-first, personalized approach for
                    marketing solutions.
                  </p>
                </div>
              </div>
            </Col>
            <Col sm="12" lg="4" md="4">
              <div className="mvv-item">
                <div className="mvv-img">
                  <img src={mission} alt="Mission" />
                </div>
                <div className="mvv-body">
                  <h4>Our Mission</h4>
                  <p>
                    To provide the best advertising and marketing solution at an
                    affordable cost.
                  </p>
                </div>
              </div>
            </Col>
            <Col sm="12" lg="4" md="4">
              <div className="mvv-item">
                <div className="mvv-img">
                  <img src={values} alt="Values" />
                </div>
                <div className="mvv-body">
                  <h4>Our Values</h4>
                  <p>
                    To be consistent in what we do and stay focused on solving
                    customer's problems at affordable cost.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Consulting message="I want to know more about your services!" />
      </div>
    </section>
  );
}

export default About;
