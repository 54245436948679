import React from "react";
import { Link } from "react-router-dom";

function Breadcrumbs(props) {
  return (
    <section className="breadcrumbs-wrapper">
      <div className="container bdc">
        <h6>
          <Link to="/">Home</Link>
          <i class="bx bx-chevron-right"></i>
          <span>{props.name}</span>
        </h6>
      </div>
    </section>
  );
}

export default Breadcrumbs;
