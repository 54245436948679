import React, { useState, useEffect } from "react";
import "./Common.css";
import { NavLink, Link } from "react-router-dom";
import RequestCallback from "../Common/RequestCallback";

// Image Imports

import logo from "../../../images/logo.png";

// End of Image Imports

function Header() {
  const [modal, setModal] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const handleScroll = () => {
    const position = window.scrollY;
    if (position > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //  Toggle Modal

  const toggle = () => setModal(!modal);

  return (
    <>
      <div className="header-top">
        <div className="container header-content">
          <h6>fish2shark Inc., Ontario, CA</h6>
          <ul className="header-top-right">
            <li className="mob-hide">
              <a href="mailto:support@fish2shark.com" className="header-links">
                <i class="bx bxs-envelope"></i>
                support@fish2shark.com
              </a>
            </li>
            <span className="mob-hide">&#9679;</span>
            <li>
              <a href="tel:+15488883355" className="header-links">
                <i class="bx bxs-phone-call"></i>
                +1 (548) 888-3355
              </a>
            </li>
            <span>&#9679;</span>
            <li>
              <Link to="/about" className="header-links">
                About us
              </Link>
            </li>
            <span>&#9679;</span>
            <li>
              <h6 onClick={toggle} className="header-links m-0 cursor-pointer">
                Request a call
              </h6>
            </li>
          </ul>
        </div>
      </div>
      <header className={`header ${scrolled ? "scrolled" : ""}`}>
        <div className="container">
          <Link to="/" className="logo-link">
            <div className="logo">
              <img src={logo} alt="Logo" />
            </div>
            <h6>
              fish2<span>shark</span>
            </h6>
          </Link>
          <div className="middle-menu-items">
            <ul className="header-menu-items">
              <NavLink to="/" className="menu-link">
                <li>Home</li>
              </NavLink>
              <NavLink to="/services" className="menu-link">
                <li>Our Services</li>
              </NavLink>
              <NavLink to="/careers" className="menu-link">
                <li>Careers</li>
              </NavLink>
              <NavLink to="/contact" className="menu-link">
                <li>Contact us</li>
              </NavLink>
            </ul>
            <div className="right-contact-options">
              <a href="tel:+15488883355">
                <button className="home-contact-btn">
                  <box-icon
                    type="solid"
                    name="phone-call"
                    animation="tada"
                    size="md"
                  ></box-icon>
                  Call Now
                </button>
              </a>
              {/* <a
                href="https://wa.me/+15488883355"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="home-contact-btn">
                  <box-icon type="logo" name="whatsapp" fill="#000"></box-icon>
                  WhatsApp
                </button>
              </a> */}
            </div>
          </div>
        </div>
      </header>
      <RequestCallback
        isOpen={modal}
        toggle={toggle}
        title="Request a call"
        message="I want to know more about your company!"
      />
    </>
  );
}

export default Header;
