import React, { useState, useEffect } from "react";

function GoToTop() {
  const [hide, setHide] = useState(false);
  const goTopHandler = () => {
    const position = window.scrollY;
    if (position >= 500) {
      setHide(true);
    } else {
      setHide(false);
    }
  };

  const handleScroller = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    window.addEventListener("scroll", goTopHandler, { passive: true });

    return () => {
      window.removeEventListener("scroll", goTopHandler);
    };
  }, []);
  return (
    <div
      className={`go-to-top ${hide ? "show" : ""}`}
      onClick={handleScroller}
    >
      <i class="bx bx-arrow-from-bottom"></i>
    </div>
  );
}

export default GoToTop;
