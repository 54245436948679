import React, { useState, useRef } from "react";
import moment from "moment";
import toast from "react-hot-toast";
import emailjs from "emailjs-com";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

function RequestCallback(props) {
  // Initial Values

  const initialValues = {
    fullname: "",
    phonenumber: "",
    email: "",
    message: props.message,
    timestamp: "",
  };
  // Initialization
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialValues);

  const { fullname, phonenumber, email, message, timestamp } = values;

  const handleChange = (event) => {
    const { target } = event;
    const { name, value } = target;
    event.persist();

    setValues({ ...values, [name]: value });
  };

  const onChangeNumber = (e, type) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      // Allow only numbers in textbox
      var num = String(e.target.value);
      setValues({
        ...values,
        [e.target.name]: num,
      });
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const form = useRef();

  const submitHandler = (e) => {
    e.preventDefault();
    if (fullname === "") {
      return toast.error("Please let us know your name");
    }
    if (phonenumber === "") {
      return toast.error("Please give us your contact number");
    }
    if (email === "") {
      return toast.error("Please give us your email", {
        id: "email",
      });
    } else if (!validateEmail(email)) {
      return toast.error("Seems like an invalid email", {
        id: "email",
      });
    }
    if (message === "") {
      return toast.error("Please enter your message");
    }
    const data = new FormData();
    data.append("Name", data[fullname]);
    data.append("Contact", data[phonenumber]);
    data.append("Email", data[email]);
    data.append("Message", data[message]);
    data.append("Timestamp", data[timestamp]);

    const form = document.getElementById("contact_form");

    setLoading(true);
    fetch(form.action, {
      method: "POST",
      body: new FormData(document.getElementById("contact_form")),
    })
      .then((response) => response.json())
      .then(async () => {
        setValues(initialValues);
        setLoading(false);
        toast.success(
          " We received your message. We will contact you shortly!"
        );
      });

    // Send Email
    emailjs
      .sendForm(
        "service_o2kguex",
        "template_a9tn4ia",
        document.getElementById("contact_form"),
        "wPsroGNxdux6_ofuz"
      )
      .then(
        () => {},
        (error) => {
          toast.error("Sorry, try again!", error);
        }
      );
  };

  return (
    <Modal
      isOpen={props.isOpen}
      className="contact-modal-wrap"
      toggle={props.toggle}
      centered
    >
      <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
      <ModalBody>
        <div className="contact-form-wrap">
          <form
            ref={form}
            id="contact_form"
            action="https://sheetdb.io/api/v1/8sidz5qcnzfp0"
            name="contact_form"
            className="contact-form"
            method="POST"
            onSubmit={submitHandler}
          >
            <div className="contact-input">
              <label htmlFor="full_name">Full Name</label>
              <input
                type="text"
                placeholder="Enter your Full Name"
                id="full_name"
                name="fullname"
                value={fullname}
                onChange={handleChange}
              />
            </div>
            <div className="contact-input">
              <label htmlFor="phone_number">Contact Number</label>
              <input
                type="text"
                placeholder="Enter your Mobile Number"
                id="phone_number"
                name="phonenumber"
                value={phonenumber}
                onChange={onChangeNumber}
                maxLength="11"
              />
            </div>
            <div className="contact-input">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                placeholder="Enter your Email Address"
                id="email"
                name="email"
                value={email}
                onChange={handleChange}
              />
            </div>
            <div className="contact-input">
              <label htmlFor="message">Message</label>
              <textarea
                type="text"
                placeholder="Enter your Message here"
                id="message"
                rows="4"
                cols="30"
                name="message"
                value={message}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="contact-input">
              <input
                hidden
                type="text"
                value={moment().format("DD-MM-YYYY hh:mm a")}
                name="timestamp"
                onChange={handleChange}
              />
            </div>
            <button className="submit-form" type="submit">
              {loading ? (
                "Requesting..."
              ) : (
                <>
                  <i class="bx bx-phone-incoming"></i>
                  Request a call
                </>
              )}
            </button>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default RequestCallback;
